<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="polls"
      theme="nocturnal"
    >
       <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item  v-if="props.row.can_visitors_vote == 0"  v-on:click="openVotes(props.row.id)">
                <feather-icon
                  icon="PlayIcon"
                  class="mr-50"
                />
                <span>Abrir Votação</span>
              </b-dropdown-item>
              <b-dropdown-item  v-if="props.row.can_visitors_vote == 1"  v-on:click="closeVotes(props.row.id)">
                <feather-icon
                  icon="StopIcon"
                  class="mr-50"
                />
                <span>Encerrar Votação</span>
              </b-dropdown-item>              
                <b-dropdown-item >
                  <router-link :to="{ name: 'poll-edit', params: { pollId: props.row.id }}">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Editar</span>
                  </router-link>
                </b-dropdown-item>
              <b-dropdown-item v-on:click="deletePoll(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Deletar</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else-if="props.column.field === 'can_visitors_vote'">
          {{ props.formattedRow[props.column.field] == 0 ? 'Fechada' : "Aberta" }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] == null ? 'Sem registro' : props.formattedRow[props.column.field] }}
        </span>
      </template>
      
    </vue-good-table>
    
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import {
   BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown, 
    BDropdownItem,
    VueGoodTable
  },
  data () {
    return {
      columns: [
      {
        label: 'ID',
        field: 'id',
      },
      {
        label: 'Pergunta',
        field: 'question',
      },
      {
        label: 'Votação',
        field: 'can_visitors_vote',
      },
      {
        label: 'Data de início',
        field: 'starts_at',
      },
      {
        label: 'Data de final',
        field: 'ends_at',
      },
      {
        label: 'Ações',
        field: 'actions',
      }
      ],
      polls: [],
    }
  },
  created() {
     this.getPolls();
  },
  methods: {
    getPolls(){
      this.$http.get('/polls/admin/get-polls')
     .then(res => {
       this.polls = res.data
       
      })
    },
    clonePoll(pollId) {
      this.$http
        .post('/polls/admin/clone-poll', {
          pollId: pollId,
        })
        .then(response => {
          this.getPolls()
        })
    },
    openVotes(pollId) {
      this.$http
        .post('/polls/admin/open-poll', {
          pollId: pollId,
        })
        .then(res => {
          this.$router.push('/current-poll');
        })
    },
    closeVotes(pollId) {
      this.$http
      .post('/polls/admin/close-poll', {
        pollId: pollId,
      })
      .then(res => {
        this.$router.push('/prev-polls');
      })
    },    
    // deletePoll(pollId) {
    //   this.$http
    //     .post('/room/delete-poll', {
    //       pollId: pollId,
    //     })
    //     .then(response => {
    //       this.getRounds()
    //     })
    // }
  },
}
</script>

<style>

</style>
